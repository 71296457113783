import * as React from "react"
import "../styles/main.scss"
import Layout from "../components/layout.js"
import Standings from "../components/standings.js"
import testData from "../content/test.JSON"


const seedList = [
    {
        name: "N1234567890",
        seed: "1234567890"
    },
    {
        name: "N-123123123",
        seed: "-123123123"
    },
    {
        name: "N777777777",
        seed: "777777777"
    },
    {
        name: "N420696666",
        seed: "420696666"
    }
]

const standings = testData;

// markup
const IndexPage = () => {
    const [selectedSeed, setSelectedSeed] = React.useState(seedList[0]);


    var unixTime = 1644883199; //Unix time for UTC 2022-02-14 11:59:59
    var d = new Date(unixTime * 1000);

    var startingUnixTime = 1643760000;
    var startingD = new Date(startingUnixTime * 1000);
    console.log(d.toLocaleString());

    return (
        <main>
            <title>Seed Cycle - Leaderboard</title>
            <Layout></Layout>

            {/* <div className="seed-summary">
                Starting 1st Feb 12:01AM
                <br></br>
                Closing Date/Time is 2022-02-14 11:59:59PM GMT
            </div>
            <p>
                In your local time: {d.toLocaleString()}
            </p>  */}
            <div className="seed-summary">
                Starting 2nd Feb 12:00AM GMT
            </div>
            <p>
                In your local time: {startingD.toLocaleString()}
            </p>

            {/*<div className="select-list">
                <label>{selectedSeed.seed}</label>
                 <ul>
                    {seedList.map((s) => (
                        <li key={s.seed} onClick={() => {setSelectedSeed(s)}}>{s.name} - {s.seed}</li>
                    ))}
                </ul>
            </div>*/}

            {/* <Standings standings={standings}></Standings> */}
            <div className={`standing`}>
                <div className="rank">
                    ...
                </div>
                <div className="runner">
                    No runs yet
                </div>
                <div className="time">
                    ...
                </div>
            </div>
        </main>
    )
}

export default IndexPage
